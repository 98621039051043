import {
  computed,
} from '@vue/composition-api'
import useTextColor from './useTextColor'

export default () => {
  const { dashboardTextColor } = useTextColor()
  const series = [
    {
      name: '회원가입',
      type: 'column',
      data: [
        // 167,
        // 154,
        // 173,
        // 189,
        202,
        329,
        272,
        285,
      ],
    },
    {
      name: '회원가입 중 캠페인 개설자',
      type: 'line',
      data: [
        // 34,
        // 29,
        // 29,
        // 37,
        51,
        81,
        71,
        65,
      ],
    },
  ]
  const options = computed(() => ({
    chart: {
      type: 'line',
      toolbar: {
        show: true,
      },
      zoom: {
        enabled: false,
      },
    },
    responsive: [{
      breakpoint: 480,
      options: {
        legend: {
          position: 'bottom',
          offsetX: -10,
          offsetY: 0,
        },
      },
    }],
    plotOptions: {
      bar: {
        horizontal: false,
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: dashboardTextColor.value,
        },
      },
    },
    xaxis: {
      type: 'string',
      categories: [
        // '2023-01',
        // '2023-02',
        // '2023-03',
        // '2023-04',
        '2023-05',
        '2023-06',
        '2023-07',
        '2023-08',
      ],
      labels: {
        style: {
          colors: dashboardTextColor.value,
        },
      },
    },
    title: {
      text: 'OnBoard',
      style: {
        color: dashboardTextColor.value,
      },
    },
    dataLabels: {
      enabled: true,
      enabledOnSeries: [1],
    },
    stroke: {
      width: [0, 4],
    },
    legend: {
      position: 'right',
      labels: {
        colors: dashboardTextColor.value,
      },
    },
    fill: {
      opacity: 1,
    },
  }))
  return {
    series,
    options,
  }
}
