<template>
  <BCard>
    <VueApexCharts
      height="300"
      :options="options"
      :series="series"
    />
  </BCard>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import { BCard } from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import usePaidUsers from '../usePaidUsers'

export default defineComponent({
  components: {
    BCard,
    VueApexCharts,
  },
  setup() {
    return {
      ...usePaidUsers(),
    }
  },
})
</script>
