<template>
  <div>
    <BRow>
      <BCol cols="6">
        <PaidUsers />
      </BCol>
      <BCol cols="6">
        <OnBoard />
      </BCol>
    </BRow>
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import {
  BRow,
  BCol,
} from 'bootstrap-vue'
import PaidUsers from './blocks/PaidUsers.vue'
import OnBoard from './blocks/OnBoard.vue'

export default defineComponent({
  components: {
    BRow,
    BCol,
    PaidUsers,
    OnBoard,
  },
  setup() {

  },
})
</script>
