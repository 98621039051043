import {
  computed,
} from '@vue/composition-api'
import useTextColor from './useTextColor'

export default () => {
  const { dashboardTextColor } = useTextColor()
  const series = [
    {
      name: '월 결제 사용자',
      data: [
        // 50,
        // 52,
        // 61,
        // 60,
        74,
        85,
        85,
        98,
      ],
    },
    {
      name: '단건 결제 사용자',
      data: [
        // 0,
        // 0,
        // 4,
        // 20,
        33,
        32,
        37,
        40,
      ],
    },
    {
      name: '전환',
      data: [
        // 0,
        // 0,
        // 0,
        // 0,
        3,
        5,
        0,
        5,
      ],
    },
  ]
  const options = computed(() => ({
    chart: {
      type: 'bar',
      stacked: true,
      toolbar: {
        show: true,
      },
      zoom: {
        enabled: false,
      },
    },
    responsive: [{
      breakpoint: 480,
      options: {
        legend: {
          position: 'bottom',
          offsetX: -10,
          offsetY: 0,
        },
      },
    }],
    plotOptions: {
      bar: {
        horizontal: false,
      },
    },
    title: {
      text: 'Paid Users',
      style: {
        color: dashboardTextColor.value,
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: dashboardTextColor.value,
        },
      },
    },
    xaxis: {
      type: 'string',
      categories: [
        // '2023-01',
        // '2023-02',
        // '2023-03',
        // '2023-04',
        '2023-05',
        '2023-06',
        '2023-07',
        '2023-08',
      ],
      labels: {
        style: {
          colors: dashboardTextColor.value,
        },
      },
    },
    legend: {
      position: 'right',
      labels: {
        colors: dashboardTextColor.value,
      },
    },
    fill: {
      opacity: 1,
    },
  }))
  return {
    series,
    options,
  }
}
