import {
  computed,
} from '@vue/composition-api'
import { $themeColors } from '@themeConfig'
import store from '@/store'

export default () => {
  const appSkinColor = computed(() => store.state.appConfig.layout.skin)
  const dashboardTextColor = computed(() => (appSkinColor.value === 'dark' ? $themeColors.light : $themeColors.dark))
  return {
    appSkinColor,
    dashboardTextColor,
  }
}
